@use "../../../sass/heading";
@use "../../../sass/paragraph";
@use "../../../sass/colors";

.projectsBannerMain {
    padding: 5rem 0 2rem;

    div {

        div {
            h1 {
                @include heading.levelOneHeading;
                color: colors.$blackColor;
                margin-bottom: 1.5rem;
            }

            p {
                @include paragraph.paragraphMixin;
                line-height: 120%;
                color: colors.$blackColor;

            }
        }
    }
}