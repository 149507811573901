@use "../../../sass/heading";
@use "../../../sass/paragraph";
@use "../../../sass/colors";

.aboutUsSectionMain {
  padding: 4rem 0;

  div {
    div {
      h3 {
        @include heading.levelThreeHeading;
        width: 60%;
        text-align: center;
      }

      p {
        width: 60%;
        text-align: center;
      }

      article {
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
        row-gap: 2rem;

        @media only screen and (max-width: 768px) {
          gap: 4%;
          row-gap: 2rem;
        }

        span {
          width: 30%;

          @media only screen and (max-width: 768px) {
            width: 48%;
          }

          @media only screen and (max-width: 500px) {
            width: 100%;
          }

          p {
            @include paragraph.paragraphMixin;

            &:first-child {
              width: 4rem;
              justify-content: center;
              border-radius: 50%;
              aspect-ratio: 1/1;
              margin-bottom: 1rem;
              color: colors.$whiteColor;
              font-weight: 700;
              font-size: 1.5rem;
            }

            &:last-child {
              width: 100%;
              text-align: left;
              font-size: 1rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
