@use "../../../../sass/heading";
@use "../../../../sass/paragraph";

.rightImageLeftText {
    margin: 4rem 0;

    div {
        div {
            article {
                display: flex;
                justify-content: center;
                gap: 4%;

                @media only screen and (max-width: "768px") {
                    flex-direction: column;
                    column-gap: 3rem;
                }

                div {
                    width: 48%;

                    h2 {
                        @include heading.levelTwoHeading;
                    }

                    p {
                        @include paragraph.paragraphMixin;
                        margin-bottom: 1rem;
                    }

                    div {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        span {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                            flex-wrap: wrap;
                            width: 4rem;
                            aspect-ratio: 1/1;
                            gap: 5px;

                            img {
                                width: 3rem;
                                height: auto;
                                margin: 0 auto;
                            }

                            p {
                                @include paragraph.paragraphMixin;
                                font-size: 12px;
                                font-weight: 700;
                                margin: 0;
                            }
                        }
                    }

                    @media only screen and (max-width: "768px") {
                        width: 100%;
                    }
                }

                aside {
                    display: block;
                    width: 48%;

                    @media only screen and (max-width: "768px") {
                        width: 100%;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: auto;


                        @media only screen and (max-width: "768px") {
                            max-width: 600px;
                            min-width: 300px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}