@use "../../../sass/colors";
@use "../../../sass/heading";
@use "../../../sass/paragraph";

.contactUsMain {
  padding: 4rem 0;

  div {
    div {
      article {
        display: flex;
        flex-wrap: wrap;
        gap: 10%;

        @media only screen and (max-width: "991px") {
          flex-direction: column;
          row-gap: 3rem;
        }

        div {
          &:first-child {
            position: relative;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            border-radius: 10px 0 0 10px;
            background: linear-gradient(
              to left,
              rgba(colors.$primaryColor, 0) 0%,
              rgba(colors.$primaryColor, 0.5) 100%
            );

            @media only screen and (max-width: "991px") {
              width: 100%;
            }

            aside {
              position: absolute;
              left: 80%;
              top: -15%;

              @media only screen and (max-width: "991px") {
                top: -40%;
              }

              @media only screen and (max-width: "768px") {
                width: 6.5rem;
                aspect-ratio: 1/1;

                img {
                  width: inherit;
                  height: inherit;
                }
              }

              &:last-of-type {
                position: absolute;
                left: 12%;
                bottom: -15%;
                top: unset;

                @media only screen and (max-width: "991px") {
                  top: unset;
                  bottom: -30%;
                  left: 5%;
                }
              }
            }

            p {
              color: colors.$blackColor;
              font-weight: 700;
              font-size: 2rem;

              p {
                color: colors.$whiteColor;
              }
            }
          }

          &:last-child {
            width: 45%;

            @media only screen and (max-width: "991px") {
              width: 100%;
            }

            h3 {
              @include heading.levelThreeHeading;
              margin-bottom: 2rem;
            }

            form {
              display: flex;
              flex-wrap: wrap;
              row-gap: 1rem;
              column-gap: 4%;

              span {
                width: 100%;
                &:first-child {
                  width: 48%;

                  input {
                    width: 100%;
                  }
                }

                &:nth-child(2) {
                  width: 48%;

                  input {
                    width: 100%;
                  }
                }

                input {
                  width: inherit;
                  font-size: 0.8rem;
                  &::placeholder {
                    font-size: 0.8rem;
                    opacity: 80%;
                    font-weight: 700;
                  }
                }
                textarea {
                  width: inherit;
                  font-size: 0.8rem;
                  &::placeholder {
                    font-size: 0.8rem;
                    opacity: 80%;
                    font-weight: 700;
                  }
                }

                button {
                  display: block;
                  margin-left: auto;
                  padding: 7px 10px;
                  border-radius: 5px;
                  background-color: colors.$contactUsLinkbgColor;
                  color: colors.$contactUsLinkColor;
                  font-size: 0.8rem;
                  font-weight: 700;
                  border: none;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }
      }
    }
  }
  .error-message {
    color: colors.$primaryColor;
    font-size: 0.5rem;
    @media only screen and (max-width: "768px") {
      font-size: 0.8rem;
    }
  }
}
