@use "../../../sass/colors" as color;
@use "../../../sass/paragraph" as paragraph;
@use "../../../sass/heading" as heading;
@use "../../../sass/animation" as animation;

.ourServicesMain {
    position: relative;
    padding: 80px 0;

    div {
        div {
            span {
                display: block;
                @include paragraph.paragraphMixin;
                letter-spacing: 2px;
                font-size: 1rem;
                color: color.$primaryColor2;
                margin-bottom: 15px;
                font-weight: 500;
            }

            h1 {
                @include heading.levelOneHeading;
                margin-bottom: 15px;
            }

            p {
                @include paragraph.paragraphMixin;
                font-size: 1.2rem;
                line-height: 115%;
                margin-bottom: 30px;
                color: color.$secondaryColor;

            }

            article {
                display: flex;
                justify-content: center;
                gap: 4%;

                @media only screen and (max-width: "768px") {
                    flex-direction: column;
                }

                div {
                    width: 48%;

                    @media only screen and (max-width: "768px") {
                        width: 100%;
                    }

                    &:first-child {
                        padding-top: 20px;

                        button {
                            position: relative;
                            border: none;
                            background-color: transparent;
                            text-align: left;
                            margin-bottom: 1.1rem;
                            padding-left: 15px;
                            opacity: 0.6;
                            transition: all 0.3s ease-in-out;

                            span {
                                font-size: 1.2rem;
                                font-weight: 600;
                                color: color.$secondaryColor;
                                margin-bottom: 0.5rem;
                            }

                            p {
                                font-size: 0.8rem;
                                font-weight: 300;
                                color: color.$secondaryColor;
                                margin-bottom: 0;
                            }
                        }
                    }

                    &:last-child {

                        div {
                            width: 100%;
                            padding: 20px;

                            span {
                                display: block;
                                width: 100%;
                                aspect-ratio: 1/0.58;
                                border-radius: 10px;
                                overflow: hidden;

                                img {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 10px;
                                    object-fit: cover;
                                    transition: all 1s ease-in-out;


                                    &:hover {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        transform: scale(110%);


                                    }
                                }

                            }

                            p {
                                @include paragraph.paragraphMixin;
                                color: color.$secondaryColor;
                                font-size: 0.9rem;
                                line-height: 120%;
                                margin-bottom: 10px;
                            }

                            a {
                                display: inline-block;
                                @include paragraph.paragraphMixin;
                                font-size: 0.85rem;
                                color: color.$primaryColor2;
                                font-weight: 600;

                                span {
                                    display: inline;
                                    margin-left: 20px;
                                    font-size: 0.8rem;
                                }
                            }
                        }


                    }

                }
            }
        }
    }
}

.activeButton {
    opacity: 1 !important;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        margin: auto 0;
        background-color: color.$primaryColor2;
    }
}

.activeService {
    animation: fade-in 0.5s forwards ease-in;
}

@include animation.fadeInAnimation(fade-in);