@use "../../../sass/heading";
@use "../../../sass/colors";
@use "../../../sass/paragraph";

.processSectionMain {
  position: relative;
  padding: 6rem 0 4rem 0;
  width: 100%;
  height: fit-content;
  background: url("../../../assets/images/about//process/processBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: left top;

  @media only screen and (max-width: "768px") {
    padding: 10rem 0 5rem;
  }

  div {
    div {
      h3 {
        @include heading.levelThreeHeading;
        margin-bottom: 1.7rem;
      }

      p {
        @include paragraph.paragraphMixin;
      }

      article {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.7rem;
        gap: 15%;
        row-gap: 2.5rem;

        @media only screen and (max-width: "768px") {
          gap: 10%;
          row-gap: 2rem;
        }

        @media only screen and (max-width: "500px") {
          flex-direction: column;
        }

        div {
          width: 37%;
          background-color: rgba(colors.$primaryColor, 0.1);
          padding: 1rem;

          @media only screen and (max-width: "500px") {
            width: 80%;
            max-width: 250px;
          }

          span {
            border-radius: 50%;
            width: 2.7rem;
            aspect-ratio: 1/1;
            display: flex;
            background-color: colors.$whiteColor;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.6rem;
          }

          p {
            font-weight: 500;
          }
        }
      }
    }
  }
}