@use "../../../sass/colors" as color;
@use "../../../sass/paragraph" as paragraph;
@use "../../../sass/heading" as heading;

.homeBannerMain {
    position: relative;
    background-image: url("../../../assets/images/home/bannerImage.png");
    background-repeat: no-repeat;
    background-size: 33%;
    background-position: 94%;
    height: 600px;

    @media only screen and (min-width: "1550px") {
        background-position: 84%;
        background-size: unset;
    }



    @media only screen and (max-width: "991px") {
        background-image: unset;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            background-image: url('../../../assets/images/home/bannerImage.png');
            background-repeat: no-repeat;
            background-position: 80%;
            background-size: 400px 400px;
        }
    }


    @media only screen and (max-width: "991px") {
        height: 500px;
    }

    @media only screen and (max-width: "768px") {
        background-image: url("../../../assets/images/home/bannerImage.png");
        background-position: bottom center;
        background-size: 18rem 18rem;
        height: max(33rem, 500px);

        &::before {
            display: none;
        }
    }

    div {
        position: relative;
        height: 100%;
        z-index: 1;

        div {
            width: 60%;
            height: 100%;

            @media only screen and (max-width: "991px") {
                width: 80%;
            }

            @media only screen and (max-width: "768px") {
                width: 100%;
                padding: 20px;

            }

            article {
                width: 100%;

                p {
                    @include paragraph.paragraphMixin;
                    font-size: 1rem;
                    margin-bottom: 15px;
                    font-weight: 500;
                }

                h1 {
                    @include heading.levelOneHeading;
                    color: color.$blackColor;
                    margin-bottom: 1.5rem;
                }

                div {
                    width: 100%;
                    max-width: 500px;
                    min-width: 400px;
                    height: fit-content;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    gap: 10px;
                    padding: 0;


                    @media only screen and (max-width: "768px") {
                        grid-template-columns: auto auto;
                        min-width: fit-content;
                    }

                    p {
                        height: fit-content;
                        margin: 0;

                        span {
                            display: block;
                            @include paragraph.paragraphMixin;

                            &:first-child {
                                color: color.$primaryColor ;
                                font-size: 1.3rem;
                                margin-bottom: 20px;
                                font-weight: 500;

                                @media only screen and (max-width: "768px") {
                                    margin-bottom: 10px;
                                }
                            }


                            &:last-child {
                                color: color.$secondaryColor ;
                                font-size: 1.1rem;
                                font-weight: 400;
                            }
                        }
                    }

                }
            }
        }

    }
}

.show {
    display: block;
    animation: animate 1s backwards;
    animation-delay: 0.5s;
    color: red;
}

.hide {
    display: none;
}

@keyframes animate {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}