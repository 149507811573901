@use "../../../sass/colors" as color;
@use "../../../sass/paragraph" as paragraph;
@use "../../../sass/heading" as heading;
@use "../../../sass/animation" as animation;

.technologiesMain {
    position: relative;
    padding: 80px 0;

    div {
        div {
            span {
                display: block;
                @include paragraph.paragraphMixin;
                letter-spacing: 2px;
                font-size: 1rem;
                color: color.$primaryColor2;
                margin-bottom: 15px;
                font-weight: 500
            }

            h1 {
                @include heading.levelOneHeading;
                margin-bottom: 15px;
            }

            p {
                @include paragraph.paragraphMixin;
                font-size: 1.2rem;
                line-height: 115%;
                margin-bottom: 30px;
                color: color.$secondaryColor;
            }

            article {
                position: relative;
                padding: 40px 0;

                div {
                    div {

                        div {
                            div {
                                display: flex !important;
                                justify-content: center;
                                flex-wrap: wrap;
                                gap: 2.5%;
                                row-gap: 30px;

                                .box {
                                    display: block;
                                    background-color: color.$primaryLightColor1;
                                    width: 18%;
                                    min-width: 180px;
                                    aspect-ratio: 1/1;
                                    padding: 20px;
                                    border-radius: 10px;
                                    display: flex !important;
                                    justify-content: center;
                                    flex-direction: column !important;
                                    flex-wrap: nowrap;
                                    row-gap: 10px;

                                    @media only screen and (max-width: "768px") {
                                        min-width: 150px;
                                    }

                                    span {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        aspect-ratio: 1/0.6;
                                        margin: 0;

                                        img {
                                            width: fit-content;

                                            @media only screen and (max-width:"768px") {
                                                width: auto;
                                                max-height: 4rem;
                                            }

                                        }
                                    }


                                    .text {
                                        display: block;
                                        margin: auto auto 0 auto;
                                        font-size: 1.15rem;
                                    }
                                }
                            }
                        }


                    }
                }
            }
        }
    }
}

.carousel {
    position: relative;

    .carousel-indicators {
        position: absolute;
        bottom: -100px;
        display: flex;
        justify-content: center;
        gap: 15px;

        button {
            width: 80px;
            height: 8px;
            background-color: color.$secondaryColor1;
            border-radius: 10px;
            border: 1px solid transparent;
            opacity: 1;
        }

        .active {
            background-color: color.$primaryColor;
        }
    }
}

// .carousel-inner .carousel-item {
//     opacity: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     display: block;
//     position: absolute;

// }

// .carousel-inner .carousel-item:first-of-type {
//     position: relative;
// }

// .carousel-inner .active {
//     opacity: 1;
// }