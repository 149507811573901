@use "../../../sass/colors" as color;

.clientsMain {
    background-color: color.$primaryLightColor1;
    width: 100%;
    padding: 40px 0;

    @media screen and (max-width:"768px") {
        padding: 10px 0;
    }

    div {
        div {
            div {
                div {
                    display: flex;
                    gap: 100px;
                    justify-content: center;
                    align-items: center;

                    @media only screen and (max-width: "768px") {
                        gap: 20px;
                    }

                    div {
                        div {
                            span {
                                height: fit-content !important;

                                img {
                                    width: 80%;
                                    min-width: 70px;
                                    min-height: 30px;
                                    transition: all 0.3s ease-in-out;
                                    transform: scale(70%);

                                    @media only screen and (max-width: "768px") {
                                        transform: scale(50%);
                                    }

                                    @media only screen and (max-width: "550px") {
                                        transform: scale(40%);
                                    }

                                    &:hover {
                                        filter: invert(0.8);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.initial-child-container {
    gap: 50px;
}