@use "../../../sass/heading";
@use "../../../sass/paragraph";
@use "../../../sass/colors";

.ourMissionMain {
  padding: 4rem 0;
  div {
    div {
      article {
        display: flex;
        align-items: center;
        gap: 4%;
        @media only screen and (max-width: "768px") {
          flex-direction: column-reverse;
          row-gap: 2rem;
        }
        span {
          width: 48%;
          @media only screen and (max-width: "768px") {
            width: 100%;
          }
          img {
            width: 100%;
            height: auto;
            max-width: 400px;
            margin: 0 auto;
            display: block;
          }
        }
        div {
          width: 48%;
          @media only screen and (max-width: "768px") {
            width: 100%;
          }
          h3 {
            @include heading.levelThreeHeading;
          }
          p {
            @include paragraph.paragraphMixin;
            margin-bottom: 1.2rem;
          }
          a {
            padding: 7px 10px;
            border-radius: 5px;
            background-color: colors.$contactUsLinkbgColor;
            color: colors.$contactUsLinkColor;
            font-size: 0.8rem;
            font-weight: 700;
            &:hover {
              background-color: colors.$contactUsHoverbgColor;
              color: colors.$contactUsLinkColor;
            }
          }
        }
      }
    }
  }
}
