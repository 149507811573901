@use "../../../sass/paragraph" as paragraph;
@use "../../../sass/heading" as heading;
@use "../../../sass/colors" as color;

.homeProjectsMain {
    position: relative;
    padding: 80px 0 150px;

    @media only screen and (max-width: "768px") {
        padding: 40px 0 100px;
    }

    div {
        div {
            span {
                display: block;
                @include paragraph.paragraphMixin;
                letter-spacing: 2px;
                font-size: 1rem;
                color: color.$primaryColor2;
                margin-bottom: 15px;
                font-weight: 500
            }

            h1 {
                @include heading.levelOneHeading;
                margin-bottom: 40px;
            }

            article {
                div {

                    div {

                        .carousel-item {


                            div {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 4%;

                                div {
                                    width: 48%;
                                    height: 100%;

                                    @media only screen and (max-width: "500px") {
                                        width: 100%;
                                    }

                                    &:first-child {
                                        display: block;

                                        p {


                                            span {
                                                display: inline;
                                                @include paragraph.paragraphMixin;
                                                font-size: 0.9rem;

                                                &:first-child {
                                                    font-weight: 600;
                                                    font-size: 1.5rem;
                                                    margin-right: 3px;
                                                }

                                                &:last-child {
                                                    color: color.$secondaryColor;
                                                    margin-left: 3px;
                                                }
                                            }

                                            &:last-of-type {
                                                max-width: 500px;
                                                font-size: 1rem;

                                                @media only screen and (max-width: "768px") {

                                                    min-height: 8rem;
                                                }

                                            }
                                        }

                                        h3 {
                                            @include heading.levelThreeHeading;
                                        }

                                        a {
                                            @include paragraph.paragraphMixin;
                                            font-size: 1.3rem;
                                            font-weight: 600;
                                            display: flex;

                                            span {
                                                display: inline-block;
                                                color: inherit;

                                                margin: {
                                                    left: 20px;
                                                    top: 5px;
                                                }

                                            }
                                        }


                                    }

                                    &:last-child {
                                        height: inherit;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        span {
                                            display: block;
                                            width: 100%;
                                            aspect-ratio: 1/0.7;

                                            img {
                                                width: inherit;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }
    }
}

.carousel {
    position: relative;

    .carousel-indicators {
        position: absolute;
        bottom: -100px;
        display: flex;
        justify-content: center;
        gap: 0.3rem;

        @media only screen and (max-width: "768px") {
            bottom: -40px;
        }

        button {
            width: 2.5rem;
            height: 0.5rem;
            background-color: color.$secondaryColor1;
            border-radius: 0.5rem;
            border: 1px solid transparent;
            opacity: 1;
        }

        .active {
            background-color: color.$primaryColor;
        }
    }
}