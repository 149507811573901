body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html {
  font-size: clamp(18px, calc(10px + 2vmin), 24px);

  @media only screen and (max-width: "768px") {
    font-size: clamp(12px, calc(8px + 1.5vmin), 20px);
  }
}

.container-lg {
  @media only screen and (min-width: "1550px") {
    max-width: 100%;
    padding: 0 15% 0 15%;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}