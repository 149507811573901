@use "../../../sass/heading";
@use "../../../sass/paragraph";
@use "../../../sass/colors";
@use "../../../sass/animation";

.aboutBannerMain {
    position: relative;
    padding: 6rem 0 4rem;
    background-color: colors.$blackColor2;
    background: linear-gradient(to right, rgba(colors.$primaryColor, 0.5) 0%, rgba(colors.$primaryColor, 1) 100%);

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: colors.$blackColor2;
        z-index: -1;
    }

    div {

        div {
            h1 {
                @include heading.levelOneHeading;
                width: fit-content;
                overflow: hidden;
                color: colors.$whiteColor;
                margin-bottom: 1.5rem;
                border-right: .12em solid colors.$whiteColor;
                white-space: nowrap;
                animation:
                    typing 3s steps(40, end),
                    blink-caret .75s step-start infinite;
            }

            p {
                @include paragraph.paragraphMixin;
                line-height: 120%;
                color: colors.$whiteColor;
                max-width: 1100px;

            }
        }
    }
}

@include animation.typingAnimation(typing);
@include animation.caretAnimation(blink-caret);