@use "./font" as fontMixin;
@use "./colors" as color;

@mixin levelOneHeading {
    @include fontMixin.font(3.3rem, 110%, 700);
    color: color.$secondaryColor;

    @media only screen and (max-width: "768px") {
        @include fontMixin.font(2.7rem, 105%, 700);
    }
}

@mixin levelTwoHeading {
    @include fontMixin.font(2.3rem, 110%, 700);
    color: color.$secondaryColor;

    @media only screen and (max-width: "768px") {
        @include fontMixin.font(2rem, 105%, 700);
    }
}

@mixin levelThreeHeading {
    @include fontMixin.font(2rem, 110%, 700);
    color: color.$secondaryColor;

    @media only screen and (max-width: "768px") {
        @include fontMixin.font(1.8rem, 105%, 700);
    }
}