@use "./colors" as color;


@mixin fadeInAnimation($animation_name) {
  @keyframes #{$animation_name} {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

@mixin typingAnimation ($animation_name) {
  @keyframes #{$animation_name} {
    from {
      width: 0
    }

    to {
      max-width: fit-content;
      width: 100%;
    }
  }
}

@mixin caretAnimation ($animation_name) {
  @keyframes #{$animation_name} {

    from,
    to {
      border-color: transparent
    }

    50% {
      max-width: fit-content;
      border-color: color.$whiteColor;
    }
  }
}