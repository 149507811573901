@use "../../../sass/colors" as color;
@use "../../../sass/font" as font_mixin;

.headerMain {
    box-shadow: 3px 6px 30px rgba(205, 205, 205, 0.25);

    @media only screen and (max-width:768px) {
        height: 80px;
    }

    nav {
        height: 100px;

        @media only screen and (max-width:768px) {
            height: 80px;
        }

        div {
            a {
                img {
                    width: 170px;

                    @media only screen and (max-width:991px) {
                        width: 130px;
                    }
                }
            }

            div {
                background-color: white;

                div {
                    align-items: center;


                    @media only screen and (max-width: "768px") {
                        align-items: center;
                    }

                    a {
                        position: relative;
                        text-decoration: none;
                        color: color.$navLinkColor;
                        transition: all 0.3s ease-in-out;
                        @include font_mixin.font(16px, 20px, 600);



                        &:last-child {
                            padding: 10px;
                            border-radius: 5px;
                            background-color: color.$contactUsLinkbgColor;
                            color: color.$contactUsLinkColor;

                            @media only screen and (min-width: "768px") {
                                margin-left: 10px;
                                padding: 10px 10px;
                            }

                            @media only screen and (min-width: "991px") {
                                margin-left: 30px;

                            }

                            &:not(.Active):hover {
                                background-color: color.$contactUsHoverbgColor;
                                color: color.$contactUsLinkColor;
                            }
                        }

                        &:not(.Active):hover {
                            color: color.$navLinkHoverColor;
                        }

                        @media only screen and (max-width: "768px") {
                            display: block;
                            width: fit-content;
                            // color: colors.$nav_link_smallScreen;
                            font-size: 1rem;
                        }
                    }
                }
            }

            .navbar-collapse {
                @media only screen and (max-width: "768px") {
                    position: absolute;
                    top: 80px;
                    left: 0;
                    right: 0;
                    // background-color: colors.$primary_light;
                    // border-top: 1px solid colors.$primary_dark;
                    z-index: 100;
                    padding: 20px 0;
                    box-shadow: 2px 4px 8px rgba(205, 205, 205, 0.3);

                }

            }
        }
    }
}

.Active {
    color: color.$navLinkHoverColor !important;
}

.Hide {
    display: none;
}

.Show {
    display: block;
}