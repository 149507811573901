// ********Header Colors*******//
$navLinkColor: #343434;
$navLinkHoverColor: #ee2737;
$contactUsLinkbgColor: #ee2737;
$contactUsLinkColor: #fff;
$contactUsHoverbgColor: #ff0015;
// ********Header Colors*******//

// ********  Body  **********//
$primaryColor: #ee2737;
$secondaryColor: #343434;

$primaryLightColor1: #fff3f4;

$primaryColor2: #f03d4b;
$secondaryColor1: #d9d9d9;

$blackColor: #000000;
$blackColor2: #1E1E1E;
$whiteColor: #ffffff;