@use "../../../sass/colors" as color;
@use "../../../sass/font" as font_mixin;

.footerMain {
  background-color: color.$primaryColor;
  color: color.$contactUsLinkColor;
  padding: 60px 0 60px 0;

  @media only screen and (max-width: 991px) {
    padding: 40px 0 40px 0;
  }

  .footerWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    @media only screen and (max-width: 991px) {
      gap: 10%;
    }

    div {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;

      &.footerLogo {
        width: 35%;
        @media only screen and (max-width: 768px) {
          width: 70%;
        }

        span {
          padding: 15px 0 15px 0;
        }
      }

      &.quickLinks {
        width: 10%;
        //margin-left: 7%;
        font-weight: 300;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          width: 70%;
        }

        @media only screen and (max-width: 991px) {
          margin-left: 2%;
        }

        a {
          margin-top: 10px;

          @media only screen and (max-width: 991px) {
            margin-top: 5px;
          }
          &:first-child {
            margin-top: 15px;

            @media only screen and (max-width: 991px) {
              margin-top: 10px;
            }
          }
        }

        span {
          font-weight: 700;
        }
      }

      &.aboutNav {
        width: 8%;
        //margin-left: 2%;
        font-weight: 300;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          width: 70%;
          margin-top: 10px;
          margin-left: 2%;
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          margin-left: 2%;
        }

        a {
          margin-top: 10px;

          @media only screen and (max-width: 991px) {
            margin-top: 5px;
          }
          &:first-child {
            margin-top: 15px;

            @media only screen and (max-width: 991px) {
              margin-top: 10px;
            }
          }
        }

        span {
          font-weight: 700;
        }
      }

      &.addressNav {
        width: 30%;

        @media only screen and (max-width: 991px) {
          width: 70%;
          margin-left: 3%;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-left: 3%;
        }

        .addressHeading,
        .contactHeading,
        .mailtHeading {
          font-weight: 700;
          margin-bottom: 15px;
          font-size: 0.875rem;
          @media only screen and (max-width: 991px) {
            margin: 15px 0 10px 0;
          }
        }
        .addressIcon,
        .contactIcon,
        .emailIcon {
          display: flex;
          align-items: center;
          font-size: 0.875rem;
          span {
            margin-left: 4px;
            font-weight: 300;
          }
        }
        .contactHeading,
        .mailtHeading {
          margin-top: 15px;
          @media only screen and (max-width: 991px) {
            margin-top: 10px;
          }
        }
        .subscribeHeading {
          margin: 43px 0 10px 0;
          font-weight: 700;
          @media only screen and (max-width: 768px) {
            justify-content: center;
            display: flex;
          }
        }
      }

      img {
        width: 160px;

        @media only screen and (max-width: 991px) {
          width: 120px;
        }
      }
    }
  }
  .socialLinks {
    position: relative;
    top: -110px;
    @media only screen and (max-width: 991px) {
      top: 16px;
      justify-content: center;
      display: flex;
    }

    span {
      font-size: 24px;
      margin-right: 24px;
    }
  }
  .license {
    margin-top: 100px;
    font-weight: 400;
    font-size: 14px;
    @media only screen and (max-width: 991px) {
      text-align: center;
      margin-top: 50px;
    }
  }

  .subscribeEmail {
    form {
      display: flex;
      align-items: center;
      width: 100%;
      input {
        width: 76%;
        border: none;
        border-radius: 10px;
        padding-left: 20px;
        outline: none;
        font-size: 0.8rem;
        max-height: 44px;
        &:hover {
          border: none;
          outline: none;
        }
        &::placeholder {
          font-size: 0.8rem;
          font-weight: 400;
          opacity: 50%;
        }
      }
      button {
        width: 24%;
        height: 38px;
        background-color: color.$primaryColor;
        border-radius: 10px;
        border: none;
        margin: 0 5px 0 5px;
        color: color.$contactUsLinkColor;
        font-weight: 400;
        font-size: 16px;
      }
    }
    max-width: 397px;
    width: 100%;
    display: flex;
    background-color: color.$contactUsLinkColor;
    border: none;
    border-radius: 10px;
    height: 46px;
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
  }
  .error-message {
    color: color.$whiteColor;
    font-size: 0.5rem;
    @media only screen and (max-width: "768px") {
      font-size: 0.8rem;
    }
  }
}
